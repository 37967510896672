import { makeAutoObservable, runInAction } from "mobx";
import { GridPagingData } from "src/models/GridPagingData";

export default class FinanceStore {

  franchisee: null | any = null;
  completedJobsRanking: null | any = null;

  grossDataOrder: string = 'margin';
  selectedContainer: string = 'default';
  selectedGuage: string = 'margin';


  completedJobsPieGrossCash: null | any = null;
  completedJobsPieNetCash: null | any = null;

  completedJobsSummary: null | any = null;
  completedJobsTable: null | any = null;
  completedJobsTableAggregation: null | any = null;

  netCashTable: null | any = null;
  netCashTableAggregation: null | any = null;

  pieActualVariance: null | any = null;

  pieProfilePerDay: null | any = null;
  pieIncomePerDay: null | any = null;
  dollarsDayRanking: null | any = null;
  dollarsDaySummary: null | any = null;
  dollarsDayGraph: null | any = null;
  dollarsDayTable: null | any = null;
  dollarsDayTableAggregation: null | any = null;

  activeJobsPieVariance: null | any = null;
  activeJobsPieProfit: null | any = null;
  activeJobsRanking: null | any = null;
  activeJobsSummary: null | any = null;
  activeJobsGraph: null | any = null;
  activeJobsTable: null | any = null;
  activeJobsTableAggregation: null | any = null;

  monthlyProfitRemaining: null | any = null;
  monthlyProfitGraph: null | any = null;
  monthlyProfitTable: null | any = null;

  jobRegistrationData: GridPagingData = { data: [], total: 0 };
  jobRegistrationInitialData: any[] = [];
  customDateFilter: any = { start: null, end: null };
  customDateData: null | any = null;

  invoiceLines: null | any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setFranchisee(value: any) {
    this.franchisee = value;
  }
  setCompletedJobsRanking(value: any) {
    this.completedJobsRanking = value;
  }
  setDollarsDayRanking(value: any) {
    this.dollarsDayRanking = value;
  }

  setGrossDataOrder(value: string) {
    this.grossDataOrder = value;
  }
  setSelectedContainer(value: string) {
    this.selectedContainer = value;
  }
  setSelectedGuage(value: string) {
    this.selectedGuage = value;
  }
  setCompletedJobsPieGrossCash(value: any) {
    this.completedJobsPieGrossCash = value;
  }
  setCompletedJobsPieNetCash(value: any) {
    this.completedJobsPieNetCash = value;
  }

  setCompletedJobsSummary(value: any) {
    this.completedJobsSummary = value;
  }
  setCompletedJobsTable(value: any) {
    this.completedJobsTable = value;
  }
  setCompletedJobsTableAggregation(value: any) {
    this.completedJobsTableAggregation = value;
  }
  setNetCashTable(value: any) {
    this.netCashTable = value;
  }
  setNetCashTableAggregation(value: any) {
    this.netCashTableAggregation = value;
  }
  setPieActualVariance(value: any) {
    this.pieActualVariance = value;
  }

  setPieProfilePerDay(value: any) {
    this.pieProfilePerDay = value;
  }
  setPieIncomePerDay(value: any) {
    this.pieIncomePerDay = value;
  }
  setDollarsDaySummary(value: any) {
    this.dollarsDaySummary = value;
  }
  setDollarsDayGraph(value: any) {
    this.dollarsDayGraph = value;
  }
  setDollarsDayTable(value: any) {
    this.dollarsDayTable = value;
  }
  setDollarsDayTableAggregation(value: any) {
    this.dollarsDayTableAggregation = value;
  }

  setActiveJobsPieVariance(value: any) {
    this.activeJobsPieVariance = value;
  }
  setActiveJobsPieProfit(value: any) {
    this.activeJobsPieProfit = value;
  }
  setActiveJobsRanking(value: any) {
    this.activeJobsRanking = value;
  }
  setActiveJobsSummary(value: any) {
    this.activeJobsSummary = value;
  }
  setActiveJobsGraph(value: any) {
    this.activeJobsGraph = value;
  }
  setActiveJobsTable(value: any) {
    this.activeJobsTable = value;
  }
  setActiveJobsTableAggregation(value: any) {
    this.activeJobsTableAggregation = value;
  }

  setMonthlyProfitRemaining(value: any) {
    this.monthlyProfitRemaining = value;
  }
  setMonthlyProfitGraph(value: any) {
    this.monthlyProfitGraph = value;
  }
  setMonthlyProfitTable(value: any) {
    this.monthlyProfitTable = value;
  }

  setJobRegistrationData(value: GridPagingData) {
    this.jobRegistrationData = value;
  }
  setJobRegistrationInitialData(values: any[]) {
    this.jobRegistrationInitialData = values;
  }
  setCustomDateFilter(value: any) {
    this.customDateFilter = value;
  }
  setCustomDateData(value: any) {
    this.customDateData = value;
  }

  setInvoiceLines(values: any) {
    this.invoiceLines = values;
  }
}