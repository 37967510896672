export enum ModalSize {
    TINY = 1,
    SMALL = 2,
    LARGE = 3,
    FULL = 4,
}

export interface ModalPopup {
    open: boolean;
    size: ModalSize;
    body: JSX.Element | null;
}


  