import { makeAutoObservable, runInAction } from "mobx";
import { ModalSize, ModalPopup } from "src/models/ModalPopup";


export default class CommonStore {

  alertMsg: any = {isOpen: false, isSuccess: false, isReload: false, title: "", message: ""};
  modalPopup: ModalPopup = {open: false, size: ModalSize.TINY, body: null}

  isSidebarShow = false;
  isMobileAccess = false; 
  
  categoryL: boolean = false;
  categoryN: boolean = false;
	categoryS: boolean = false;

  selectedStateBdm: any = null;

  
  constructor() {
    makeAutoObservable(this);
  }

  setAlertMsg(value: any) {
    this.alertMsg = value;
  }

  setAlertMsgOpen = (value: boolean) => {
    this.alertMsg.isOpen = value;
  }
  
  setSidebarShow = (value: boolean) => {
    this.isSidebarShow = value; 
  }

  openModalPopup = (content: JSX.Element, size: ModalSize) => {
    this.modalPopup.open = true;
    this.modalPopup.size = size;
    this.modalPopup.body = content;
  }

  closeModalPopup = () => {
    this.modalPopup.open = false;
    this.modalPopup.body = null;
  }

  setMobileAccess = (value: boolean) => {
    this.isMobileAccess = value;
  }

  setCategoryL(value: boolean) {
    this.categoryL = value;
  }
  setCategoryN(value: boolean) {
    this.categoryN = value;
  }
  setCategoryS(value: boolean) {
    this.categoryS = value;
  }

  setSelectedStateBdm(value: any) {
    this.selectedStateBdm = value;
  }

  get isActiveCategory() {

    if (this.categoryL || this.categoryN || this.categoryS) {
      return false;
    }

    return true;
  }

}