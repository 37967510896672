import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../stores/store';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 60000;

axios.interceptors.request.use(config => {
    const bearerToken = store.userStore.accessToken;
    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`
    }

    const account = store.userStore.selectedAccount;
    if (account) {
      config.headers.UserId = account.userId;
    }

    return config;
})

axios.interceptors.response.use(async response => {

    return response;
}, (error: AxiosError) => {

  console.log(JSON.stringify(error.response));

  if (!error.response) {
    store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "No Response Error", 
        message: "Check API server or Network status"})
    return;
  }

  switch(error.response?.status) {
    case 400:
      store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "Bad Request", 
          message: error.response?.data.length == 0 ? "Check API server or Network status" : error.response?.data.toString()})
      break;
    case 401:
      // store.userStore.setAccessToken( null );    
      if (store.userStore.isVisibilitychange) {
        window.location.reload();
      }
      else {
        store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, isReload: true, 
           title: "Token Expired or Session Timeout", 
           message: "<span style='font-size: 16px'>To refresh token, please click the below 'RELOAD' button</span><br><br><span style='font-size: 13px'>If you still have this issue, please logout and login again</span>"});
      }
      break;  
    case 404:
      store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "Not Found", 
        message: error.response?.data.length == 0 ? "Your Request Not Found" : error.response?.data.toString()});
        break;     
    case 408:
      store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "Request Timeout", 
          message: "Try it again or check network status"})
      break;      
    case 415:
      store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "Unsupported Media Type", 
          message: "Invalid Request URL or Query Parameter"})
      break;  
    case 500:
      store.commonStore.setAlertMsg({isOpen: true, isSuccess: false, title: "Internal Server Error", 
          message: error.response?.data.length == 0 ? "If this error happens again, please notify Support Office - IT Team<br> Email: <a href='mailto:itsupport@hotondo.com.au'>itsupport@hotondo.com.au</a>" : 
              error.response?.data.toString().substring(0, 300)})
      break;
  }
  // return Promise.reject(error);
  return "";
})
 
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    postLongWait: <T>(url: string, body: {}) => axios.post<T>(url, body, {timeout: 150000}).then(responseBody), 
}


