import { makeAutoObservable, runInAction } from "mobx";
import { GridPagingData } from "src/models/GridPagingData";

export default class MyBusinessStore {

  drilledSalesAnalysis: null| any = null;
  stateBDMDrilledSalesAnalysis: null| any = null;

  leadManagement: null| any = null;
  leadManagementRealtime: null| any = null;

  pipleline: null| any = null;
  buildHistory: null| any = null; 
  conversionHistory: null| any = null; 
  customDateFilter : null| any = null;
  customDateData: null| any = null;
  stateBdmCustomDateData: null| any = null;
  leadMgmtDrillDown: null| any = null;
  leadMgmtPercentageDrillDown: null| any = null;
  pipelineDrillDown: null| any = null;
  nationalFranchisee: null| any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLeadManagement(value: any) {
    this.leadManagement = value;
  }

  setLeadManagementRealtime(value: any) {
    this.leadManagementRealtime = value;
  }

  setPipleline(value: any) {
    this.pipleline = value;
  }

  setBuildHistory(value: any) {
    this.buildHistory = value;
  }

  setConversionHistory(value: any) {
    this.conversionHistory = value;
  }

  setCustomDateFilter(value: any) {
    this.customDateFilter = value;
  }

  setCustomDateData(value: any) {
    this.customDateData = value;
  }


  // stateBdmCustomDateData
  setStateBdmCustomDateData(value: any) {
    this.stateBdmCustomDateData = value;
  }

  setDrilledSalesAnalysis(value: any) {
    this.drilledSalesAnalysis = value;
  }

  // stateBdmDrilledAnalysisData
  setStateBDMDrilledAnalysisData(value: any) {
    this.stateBDMDrilledSalesAnalysis = value;
  }

  setLeadMgmtDrillDown(value: any) {
    this.leadMgmtDrillDown = value;
  }

  setLeadMgmtPercentageDrillDown(value: any) {
    this.leadMgmtPercentageDrillDown = value;
  }

  setPipelineDrillDown(value: any) {
    this.pipelineDrillDown = value;
  }


  setNationalFranchisee(value: any) {
    this.nationalFranchisee = value;
  }

}