import { makeAutoObservable, runInAction } from "mobx";
import { GridPagingData } from "src/models/GridPagingData";

export default class SalesStore {

  optionsData: GridPagingData = {data: [], total: 0}; 

  versionGroupOptions: any[] = [];

  versionItemEnhance: any[] = [];
  versionItemNonStd: any[] = [];
  versionItemTerms: any[] = [];

  checklistItems: any[] = [];   // checklist for sales lead...
  selectedUnit: any|null = null;

  remainReminders: any[] | null = null;  // reminders which are remained for 'My Sales'
  conversionRate: any = null;   // guage of conversion rate for 'My Sales'
  consultantSales: any[] = [];  // consultant sales ranking for 'My Sales'
  todayFollowUps: any[] = [];  // follow up for 'My Sales'

  engagementList: any[] | null = null;  // Elements except Reatlime at Engagement Panel.. 

  constructor() {
    makeAutoObservable(this);
  }

  setOptionsData(value: GridPagingData) {
    this.optionsData = value;
  }

  setVersionGroupOptions(values: any[]) {
    this.versionGroupOptions = values;
  }

  setVersionItemEnhance(values: any[]) {
    this.versionItemEnhance = values;
  }

  setVersionItemNonStd(values: any[]) {
    this.versionItemNonStd = values;
  }

  setVersionItemTerms(values: any[]) {
    this.versionItemTerms = values;
  }

  setChecklistItems(values: any[]) {
    this.checklistItems = values;
  }

  setSelectedUnit(value: any) {
    this.selectedUnit = value;
  }

  setConsultantSales(value: any) {
    this.consultantSales = value;
  }

  setRemainReminders(value: any) {
    this.remainReminders = value;
  }

  setConversionRate(value: any) {
    this.conversionRate = value;
  }

  setTodayFollowUps(values: any[]) {
    this.todayFollowUps = values;
  }

  setEngagementList(values: any[]) {
    this.engagementList = values;
  }
 
}