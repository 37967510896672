import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../stores/store';
import { SimpleResultDto } from 'src/models/SimpleResultDto';
import { UserDetailDto } from 'src/models/UserDetailDto';
import { CreateAccountDto } from 'src/models/CreateAccountDto';
import * as CustomAxios from './CustomAxios'

const Home = {
  getHomeDebt: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Debt/${franchiseeNumber}`),
  getHomeToDo: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/ToDo/${franchiseeNumber}`),
  getHomeOperational: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Operational/${franchiseeNumber}`),
  getHomeLoading: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Load/${franchiseeNumber}`),
  getHomeSales: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Sales/${franchiseeNumber}`),
  getHomeFinance: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Finance/${franchiseeNumber}`),

  getWarmTransferSuccessDrillDown: (franchiseeNumber: Number, leadsKpiName: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/WarmTransferSuccessPercentage/${franchiseeNumber}?leadsKpiName=${leadsKpiName}`),
  getContactWithinDayDrillDown: (franchiseeNumber: Number, leadsKpiName: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/ContactWithinDayPercentage/${franchiseeNumber}?leadsKpiName=${leadsKpiName}`),

  getWarmTransferSuccessExportList: (franchiseeNumber: Number, leadsKpiName: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/WarmTransferSuccessPercExportDetails/${franchiseeNumber}?leadsKpiName=${leadsKpiName}`),
  getContactWithinDayExportList: (franchiseeNumber: Number, leadsKpiName: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/ContactWithinDayPercExportDetails/${franchiseeNumber}?leadsKpiName=${leadsKpiName}`),


  getLeadManagement: (franchiseeNumber: Number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/LeadManagement/${franchiseeNumber}`),
  getLeadManagementRealtime: (franchiseeNumber: Number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/LeadManagementRealtime/${franchiseeNumber}`),
  getPipline: (franchiseeNumber: Number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Pipeline/${franchiseeNumber}`),
  getConversionHistoryByFinancialYear: (franchiseeNumber: Number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/HistoricalConversion/${franchiseeNumber}`),


  getDebtDrillDown: (franchiseeNumber: Number, debtKpiName: String, startDateString: String, endDateString: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/DebtDrillDown/${franchiseeNumber}?debtKpiName=${debtKpiName}&startDate=${startDateString}&endDate=${endDateString}`),
  getDebtExportList: (franchiseeNumber: Number, debtKpiName: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/DebtExportList/${franchiseeNumber}?debtKpiName=${debtKpiName}`),


  getHomeVersion: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Home/Version`),
}

const HomeStateBDM = {
  getHomeStateBdmDebt: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBDM/Debt/${stateBDM}`),

  getDebtStateBDMExportList: (stateBDM: any, debtKpiName: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBDM/StateBDMDebtExportList/${stateBDM}?debtKpiName=${debtKpiName}`),


  getStateBdmDebtDrillDown: (stateBDM: any, debtKpiName: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBDM/StateBDMDebtDrillDown/${stateBDM}?debtKpiName=${debtKpiName}`),

  getHomeStateBdmToDo: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBDM/ToDo/${stateBDM}`),

  getStateBdmOperational: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/Operational/${stateBDM}`),

  getStateBdmSales: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/Sales/${stateBDM}`),

  getStateBdmLeadManagement: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/LeadManagement/${stateBDM}`),


  // getWarmTransferSuccessExportStateBdmList(FNo, modalflag);
  getWarmTransferSuccessExportStateBdmList: (stateBDM: any, leadsKpiName: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/WarmTransferSuccessPercExport/${stateBDM}?leadsKpiName=${leadsKpiName}`),
  // getContactWithinDayExportStateBdmList(FNo, modalflag);
  getContactWithinDayExportStateBdmList: (stateBDM: any, leadsKpiName: String) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/ContactWithinDayPercExport/${stateBDM}?leadsKpiName=${leadsKpiName}`),




  getStateBdmPipline: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/Pipline/${stateBDM}`),

  getStateBdmConversionHistoryByFinancialYear: (stateBDM: any) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/HomeStateBdm/HistoricalConversion/${stateBDM}`),


}

const WebUser = {
  createAccount: (value: CreateAccountDto) => CustomAxios.requests.post<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/createAccount`, value),
  signUpAccount: (value: any) => CustomAxios.requests.post<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/signUpAccount`, value),

  getAllAccounts: (email: string) => CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/${email}`),
  getAllHeadOfficeUsers: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/headOfficeUsers`),
  reqestUserInit: (value: UserDetailDto) => CustomAxios.requests.post<any>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/requestUserInit`, value),
  authUserProxy: (value: any) => CustomAxios.requests.put<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/WebUsersFranchisees/authProxyUser`, value),
}


const Franchisee = {
  getActiveFranchisees: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Franchisee/ActiveFranchisees/`),
  getFranchiseeByCategory: (category: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Franchisee/List/${category}`),
  getStateBdmList: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Franchisee/StateBdm/`),
  getAllFranchisees: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Franchisee/AllFranchisees/`),
  getFranchiseeAddress: (franchiseeNumber: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/Franchisee/Address/${franchiseeNumber}`),
}

const BelNote = {
  getBelList: (franchiseeNumber: number, filter: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/odata/FranchiseeBel?franchiseeNumber=${franchiseeNumber}&$count=true&${filter}`),
  getBelPreload: (needBelCodeList: boolean, needFranchiseeList: boolean, needHOUserList: boolean) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBel/Preload?needBelCode=${needBelCodeList}&needFranchisee=${needFranchiseeList}&needHOUser=${needHOUserList}`),
  getBelDetail: (belId: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBel/${belId}`),
  addBelNote: (details: any) =>
    CustomAxios.requests.post<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBel/`, details),
  updateBelNote: (details: any) =>
    CustomAxios.requests.put<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBel/`, details),
  deleteBelNote: (belId: number) =>
    CustomAxios.requests.delete<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBel/${belId}`),
}

const BelPending = {
  getPendingRecords: (userId: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBelPending/${userId}`),
  deleteRecord: (pendingId: number) =>
    CustomAxios.requests.delete<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeBelPending/Delete/${pendingId}`),
}

const ConsultantAction = {

  getAllConsultantDetails: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/SupportOffice/Consultant`),

  updateConsultantDetails: (details: any) =>
    CustomAxios.requests.put<SimpleResultDto>(process.env.REACT_APP_H360_API_URL + `/api/SupportOffice/UpdateDetails`, details),
}


const MyTerritory = {

  getTerritoryMap: (franchiseeNumber: Number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/MyTerritoryMap/territoryMap/${franchiseeNumber}`),
}

const MemberContact = {

  getMemberContacts: (fn: Number, category: string, act: boolean, nsw: boolean, qld: boolean, sa: boolean, tas: boolean, vic: boolean,
    salesPerson: boolean, estimator: boolean, bookKeeper: boolean, supervisor: boolean, contractAdmin: boolean,
    primaryContact: boolean, confidContact: boolean, insurance: boolean, userAdmin: boolean) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/MemberContact/${fn}/${category}/${act}/${nsw}/${qld}/${sa}/${tas}/${vic}/${salesPerson}/${estimator}/${bookKeeper}/${supervisor}/${contractAdmin}/${primaryContact}/${confidContact}/${insurance}/${userAdmin}`),

  getContactListByCategory: (category: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/MemberContact/List/${category}`),
}

const FranchiseeToDo = {

  getToDoAllList: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDo/list`),
  getToDoActiveList: () =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDo/activeList`),
  addToDo: (details: any) =>
    CustomAxios.requests.post<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDo/add`, details),
  updateToDoAndSubtasks: (details: any) =>
    CustomAxios.requests.put<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDo/update`, details),
  deleteToDo: (toDoId: number) =>
    CustomAxios.requests.delete<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDo/delete/${toDoId}`),
}

const ToDoSubtask = {

  getSubtaskList: (toDoId: number) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/api/FranchiseeToDoSubtask/list/${toDoId}`),
}

const QuickSearch = {

  getSimpleLeadList: (franchiseeNumber: number, searchText: string, filter: string) =>
    CustomAxios.requests.get<any>(process.env.REACT_APP_H360_API_URL + `/odata/LeadQuickSearch?franchiseeNumber=${franchiseeNumber}&searchText=${searchText}&$count=true&${filter}`),
}

const ApiAgent = {
  Home,
  HomeStateBDM,
  WebUser,
  BelNote,
  BelPending,
  MemberContact,
  Franchisee,
  ConsultantAction,
  MyTerritory,
  FranchiseeToDo,
  ToDoSubtask,
  QuickSearch,
}

export default ApiAgent;
