
export const TokenExpireReloadTime = "TokenExpireReloadTime"

export const UserAllAccounts = "UserAllAccounts";
export const SelectedAccount = "SelectedAccount";
export const FavoriteMenus = "FavoriteMenus";

export const ActiveFranchiseeList = "ActiveFranchiseeList"
export const FranchiseeList = "FranchiseeList-"
export const AllFranchiseeList = "AllFranchiseeList";  // 'A', 'L', 'S', 'N' categories Franchisees

// Contact list of Franchisee Staff and Owner...
export const FranchiseeContactList = "FranchiseeContactList-"
export const StateBdmList = "StateBdmList"

export const ApplicationVersion = "ApplicationVersion"
export const LastVersionTime = "LastVersionTime"

// Google Analytics
export const GA_LastAccessLink = "GA_LastAccessLink"

// Home 
export const Home_Sales = "Home_Sales-"
export const Home_Operational = "Home_Operational-"
export const Home_Finance = "Home_Finance-"


export const Home_StateBDM_Operational = "Home_StateBDM_Operational-"
export const Home_StateBDM_Finance = "Home_StateBDM_Finance-"

export const NotShowAgainToday = "NotShowAgainToday";
export const NotShowAgainBanner = "NotShowAgainBanner";

// MyBusiness
export const MyBusiness_CustomDateRange = "MyBusiness_CustomDateRange-"
export const MyBusiness_StateBDMCustomDateRange = "MyBusiness_StateBDMCustomDateRange-"

export const MyBusiness_Lead_Management = "MyBusiness_Lead_Management-"
export const MyBusiness_Pipline = "MyBusiness_Pipline-"
export const MyBusiness_BuildHistory = "MyBusiness_BuildHistory-"
export const MyBusiness_ConversionHistory = "MyBusiness_ConversionHistory-"

export const MyBusiness_StateBDM_Lead_Management = "MyBusiness_StateBDM_Lead_Management-"
export const MyBusiness_StateBDM_Pipline = "MyBusiness_StateBDM_Pipline-"
export const MyBusiness_StateBDM_ConversionHistory = "MyBusiness_StateBDM_ConversionHistory-"

//MyProfile
export const WebAccessLevel = "WebAccessLevel-"

// Business Entity Log
export const BelCodeList = "BelCodeList"
export const HeadOfficeUserList = "HeadOfficeUserList"

// Marketing - Marketing Dashboard
export const Marketing_Merchandise = "Marketing_Merchandise"
export const Marketing_Leaderboard = "Marketing_Leaderboard-"

// Marketing - EDM
export const EDM_News_Titles = "EDM_News_Titles"

// Marketing - House & LAND
export const HL_Areas = "HL_Areas-"
export const HL_Contacts = "HL_Contacts-"
export const HL_RefInclusions = "HL_RefInclusions"
export const HL_Designs = "HL_Designs-"
export const HL_RefSlogans = "HL_RefSlogans"

// Markeing - Single Request
export const SingleRequest_Layout = "SingleRequest_Layout"
export const SingleRequest_Owners = "SingleRequest_Owner-"
export const SingleRequest_Assignees = "SingleRequest_Assignee"

// Marketing - Campaign Request

// Sales - My Sales 
export const MySales_ConsultantSales = "MySales_ConsultantSales"
export const MySales_ConversionRate = "MySales_ConversionRate-"
export const Sales_Kpi_FranchiseeList = "Sales_Kpi_FranchiseeList-"

// Sales - Sales Estimating Tool 
export const DesignsForQuote = "DesignsForQuote"
export const DesignsByState = "DesignByState-"
export const CurrentDesigns = "CurrentDesigns"
export const Quote_DesignPrice = "Quote_DesignPrice-"
export const Quote_FacadesByDesign = "Quote_FacadesByDesign-"
export const RefQuoteGroups = "RefQuoteGroups";
export const RefDesignOptionGroups = "RefDesignOptionGroups";
export const TermsAndCondition = "TermsAndCondtions-"

// Sales Leads Online
export const Sales_SourceEnquiry = "Sales_SourceEnquiry"
export const SalesConsultant = "SalesConsultant-"
export const HouseStorey = "HouseStorey"
export const LeadSource = "LeadSource"
export const EventSource = "EventSource"
export const Sales_Promotion = "Sales_Promotion"
export const Columns_ViewMode = "Columns_ViewMode-"
export const Sales_Leads_Sort = "Sales_Leads_Sort-"

export const TypeOfAction = "TypeOfAction"
export const Sales_HoldReason = "Sales_HoldReason"
export const Sales_MarketingCampaign = "Sales_MarketingCampaign"
export const Sales_ReasonLost = "Sales_ReasonLost"
export const Sales_NotSalesEnquiry = "Sales_NotSalesEnquiry"
export const Sales_ResourceContainer = "Sales_ResourceContainer-"

// Building Services
export const Building_Documents = "Building_Documents"

// Recruitment Leads Online
export const EnquirySource = "EnquirySource"
export const RecLeadSource = "RecLeadSource"
export const RecConsultant = "RecConsultant"
export const RecCampaign = "RecCampaign"
export const TerritoriesRecruiting = "TerritoriesRecruiting"
export const State = "State"
export const PrivacyPolicyMethod = "PrivacyPolicyMethod"
export const LeadStatus = "LeadStatus"
export const CurrentLeadStatus = "CurrentLeadStatus"
export const Columns_RecViewCurrent = "Columns_RecViewCurrent"
export const RecTypeOfAction = "RecTypeOfAction"

// Finance Dashboard
export const Finance_FranchiseeList = "Finance_FranchiseeList"
export const Finance_CompletedJobsRanking = "Finance_CompletedJobsRanking"

export const Finance_CompletedJobsPieGrossCash = "Finance_CompletedJobsPieGrossCash-"
export const Finance_CompletedJobsPieNetCash = "Finance_CompletedJobsPieNetCash-"
export const Finance_CompletedJobsSummary = "Finance_CompletedJobsSummary-"
export const Finance_CompletedJobsTable = "Finance_CompletedJobsTable-"
export const Finance_CompletedJobsTableAggregation = "Finance_CompletedJobsTableAggregation-"
export const Finance_NetCashTable = "Finance_NetCashTable-"
export const Finance_NetCashTableAggregation = "Finance_NetCashTableAggregation-"

export const Finance_ActualVariancePie = "Finance_ActualVariancePie-"
export const Finance_ActaulVarianceTable = "Finance_ActaulVarianceTable-"
export const Finance_ActaulVarianceTableAggregation = "Finance_ActaulVarianceTableAggregation-"

export const Finance_ProfilePerDayPie = "Finance_ProfilePerDayPie-"
export const Finance_IncomePerDayPie = "Finance_IncomePerDayPie-"
export const Finance_DollarsDayRanking = "Finance_DollarsDayRanking"
export const Finance_DollarsDaySummary = "Finance_DollarsDaySummary-"
export const Finance_DollarsDayGraph = "Finance_DollarsDayGraph-"
export const Finance_DollarsDayTable = "Finance_DollarsDayTable-"
export const Finance_DollarsDayTableAggregation = "Finance_DollarsDayTableAggregation-"

export const Finance_ActiveJobsForecastVariancePie = "Finance_ActiveJobsForecastVariancePie-"
export const Finance_ActiveJobsForecastProfitPie = "Finance_ActiveJobsForecastProfitPie-"
export const Finance_ActiveJobsSummary = "Finance_ActiveJobsSummary-"
export const Finance_ActiveJobsRanking = "Finance_ActiveJobsRanking"
export const Finance_ActiveJobsGraph = "Finance_ActiveJobsGraph-"
export const Finance_ActiveJobsTable = "Finance_ActiveJobsTable-"
export const Finance_ActiveJobsTableAggregation = "Finance_ActiveJobsTableAggregation-"

export const Finance_MonthlyProfitRemainCashflowPie = "Finance_MonthlyProfitRemainCashflowPie-"
export const Finance_MonthlyProfitRemainProfitPie = "Finance_MonthlyProfitRemainProfitPie-"
export const Finance_MonthlyProfitSummary = "Finance_MonthlyProfitSummary-"
export const Finance_MonthlyProfitGraph = "Finance_MonthlyProfitGraph-"
export const Finance_MonthlyProfitTable = "Finance_MonthlyProfitTable-"
export const Finance_MonthlyProfitTableAggregation = "Finance_MonthlyProfitTableAggregation-"

// Finance - Forms
export const Finance_FormsGroup = "Finance_FormsGroup"

// Finance - Insurance Services
export const Insurance_StatesRate = "Insurance_StatesRate"

// Finance - Job Registration
export const JR_Design = "JR_Design";
export const JR_Facade = "JR_Facade";
export const JR_FloorLevel = "JR_FloorLevel";
export const JR_DesignType = "JR_DesignType";
export const JR_ConstructionType = "JR_ConstructionType";
export const JR_BuildingFor = "JR_BuildingFor";
export const JR_SuburbPostcode = "JR_SuburbPostcode";
export const SalesLeadToRegister = "SalesLeadToRegister";
export const JR_FacadeStyle = "JR_FacadeStyle";

// Finance - Jobs
export const JobNoForInvoice = "JobNoForInvoice";

// Finance - Invoice
export const Invoice_InvoiceType = "Invoice_InvoiceType";
export const Invoice_InvoiceTypeGroup = "Invoice_InvoiceTypeGroup";

// Support - Training Payment
export const Training_PayerDetails = "Training_PayerDetails";
export const Training_AttendeeList = "Training_AttendeeList";
export const Training_PaymentDetails = "Training_PaymentDetails";

// Support - Franchisee Contacts
export const Support_FranchiseeContacts = "Support_FranchiseeContacts"

// Administration - Client Enquiries
export const Designs_Enquiries = "Designs_Enquiries";
export const CallCenterOperators = "CallCenterOperators";
export const TypeOfCall_Enquiries = "TypeOfCall_Enquiries";
export const Franchisee_Town = "Franchisee_Town";
export const BuildingManagers = "BuildingManagers"

// Administration - Design Master
export const FacadeNames = "FacadeNames";

// Administration - Franchisee Master
export const CurrentBDMList = "CurrentBDMList";
export const Territories = "Territories";



// Administration - All Franchisee Reports
export const FranchiseeReport_CustomDateRange = "FranchiseeReport_CustomDateRange"
export const FranchiseeReport_AllBuilderHistory = "FranchiseeReport_AllBuilderHistory-"
export const FranchiseeReport_OtherFranchiseeHistory = "FranchiseeReport_OtherFranchiseeHistory-"

// Administration - Territory Definitions
export const Territory_ZoneList = "Territory_ZoneList"
export const Territory_ABS_Area = "Territory_ABS_Area-"

