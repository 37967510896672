import { makeAutoObservable, runInAction } from "mobx";

export default class HomeStore {

  debt: null| any = null;
  operational: null| any = null;
  sales: null| any = null;
  finance: null| any = null; 
  toDo: null| any = null;
  leadManagement: null| any = null;
  leadManagementRealtime: null| any = null; 
  pipleline: null| any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDebt(value: any) {
    this.debt = value;
  }

  setOperational(value: any) {
    this.operational = value;
  }

  setSales(value: any) {
    this.sales = value;
  }

  setFinance(value: any) {
    this.finance = value;
  }

  setToDo(value: any) {
    this.toDo = value;
  }

  setLeadManagementRealtime(value: any) {
    this.leadManagementRealtime = value;
  }

  setLeadManagement(value: any) {
    this.leadManagement = value;
  }
  
  setPipleline(value: any) {
    this.pipleline = value;
  }
}