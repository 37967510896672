import { observer } from 'mobx-react-lite';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useStore } from 'src/stores/store';
import { ModalSize } from "src/models/ModalPopup";

export default observer(function ModalContainer() {
    const {commonStore} = useStore();

    return (
        <Modal closeIcon open={commonStore.modalPopup.open} onClose={commonStore.closeModalPopup} 
                size={commonStore.modalPopup.size === ModalSize.TINY ? 'tiny' : 
                      commonStore.modalPopup.size === ModalSize.LARGE ? 'large' : 
                      commonStore.modalPopup.size === ModalSize.FULL ? 'fullscreen' : 'small' }
                style={{zIndex: "1305"}}      >
            <Modal.Content style={{padding: "1rem 0"}}>
                {commonStore.modalPopup.body}
            </Modal.Content>
        </Modal>
    )
})