import { makeAutoObservable, runInAction } from "mobx";
import { GridPagingData } from "src/models/GridPagingData";

export default class RecruitmentStore {

    recruitmentData: GridPagingData = { data: [], total: 0 };
    recruitmentLeadInitialData: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setRecruitmentData(value: GridPagingData) {
        this.recruitmentData = value;
    }
    setRecruitmentLeadInitialData(values: any[]) {
        this.recruitmentLeadInitialData = values;
    }
}