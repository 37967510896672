import { makeAutoObservable, runInAction } from "mobx";

export default class MarketingStore {

  selectedPeriod: null| any = null;
  selectedMenu: null| any = null;

  leaderBoard: null| any = null;

  bucketDetails: null| any = null;
  leadsDirectSupport: null| any = null;

  gaBuilderPageVisits: null| any = null;

  requests: null| any = null;
  requestsTable: null| any = null;
  leadEnquirySourceHL: null| any = null;

  leadsByEnquirySource: null| any = null;
  leadsByBuyerType: null| any = null;

  leadsByLeadSource: null| any = null;

  gaBuilderUserVisits: null| any = null;
  starRating: null| any = null;
  outstandingRequestsTable: null| any = null;
  sentEDMTable: null| any = null;
  leadActionsEDM: null| any = null;
  last10ModifiedPackages: null| any = null;
  facebookLeadsRequests: null| any = null;

  leadsDupNotEnquiry: null| any = null;
  leadsBuyerTypeTbaNotEnquiry: null| any = null;
  domainPackageStats: null| any = null;
  domainPackagesVsListing: null| any = null;
  top3PackagesByEnquiries: null| any = null;
  top3PackagesByPhotoViews: null| any = null;
  top3PackagesByListingViews: null| any = null;
  customDateFilterLAM: any = { start: null, end: null };


  constructor() {
    makeAutoObservable(this);
  }

  setSelectedPeriod(value: any) {
    this.selectedPeriod = value;
  }
  
  setSelectedMenu(value: any) {
    this.selectedMenu = value;
  }

  setLeaderBoard(value: any) {
    this.leaderBoard = value;
  }

  setBucketDetails(value: any) {
    this.bucketDetails = value;
  }
  setLeadsDirectSupport(value: any) {
    this.leadsDirectSupport = value;
  }

  setGaBuilderPageVisits(value: any) {
    this.gaBuilderPageVisits = value;
  }

  setRequests(value: any) {
    this.requests = value;
  }
  setRequestsTable(value: any) {
    this.requestsTable = value;
  }
  setLeadEnquirySourceHL(value: any) {
    this.leadEnquirySourceHL = value;
  }

  setLeadsByEnquirySource(value: any) {
    this.leadsByEnquirySource = value;
  }
  setLeadsByBuyerType(value: any) {
    this.leadsByBuyerType = value;
  }

  setLeadsByLeadSource(value: any) {
    this.leadsByLeadSource = value;
  }

  setGaBuilderUserVisits(value: any) {
    this.gaBuilderUserVisits = value;
  }
  setStarRating(value: any) {
    this.starRating = value;
  }
  setOutstandingRequestsTable(value: any) {
    this.outstandingRequestsTable = value;
  }
  setSentEDMTable(value: any) {
    this.sentEDMTable = value;
  }
  setLeadActionsEDM(value: any) {
    this.leadActionsEDM = value;
  }
  setLast10ModifiedPackages(value: any) {
    this.last10ModifiedPackages = value;
  }
  setFacebookLeadsRequests(value: any) {
    this.facebookLeadsRequests = value;
  }

  setLeadsDupNotEnquiry(value: any) {
    this.leadsDupNotEnquiry = value;
  }
  setLeadsBuyerTypeTbaNotEnquiry(value: any) {
    this.leadsBuyerTypeTbaNotEnquiry = value;
  }
  setDomainPackageStats(value: any) {
    this.domainPackageStats = value;
  }
  setDomainPackagesVsListing(value: any) {
    this.domainPackagesVsListing = value;
  }
  setTop3PackagesByEnquiries(value: any) {
    this.top3PackagesByEnquiries = value;
  }
  setTop3PackagesByPhotoViews(value: any) {
    this.top3PackagesByPhotoViews = value;
  }
  setTop3PackagesByListingViews(value: any) {
    this.top3PackagesByListingViews = value;
  }
  setCustomDateFilterLAM(value: any) {
    this.customDateFilterLAM = value;
  }
 
}