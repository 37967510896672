import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { store, StoreContext } from './stores/store'
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { StyledEngineProvider } from '@mui/material/styles';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/en-AU/numbers.json';
import currencies from 'cldr-numbers-full/main/en-AU/currencies.json';
import caGregorian from 'cldr-dates-full/main/en-AU/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/en-AU/dateFields.json';

dateFields.main['en-AU'].dates.fields.day.displayName = 'dd';
dateFields.main['en-AU'].dates.fields.month.displayName = 'mm';
dateFields.main['en-AU'].dates.fields.year.displayName = 'yyyy';

caGregorian.main['en-AU'].dates.calendars.gregorian.dateFormats.short = 'dd/MM/yyyy';

load(
  likelySubtags,
  currencyData,
  weekData,
  currencies,
  numbers,
  caGregorian,
  dateFields
);


ReactDOM.render(
  <StoreContext.Provider value={store}>
    <IntlProvider locale="en-AU" >
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </IntlProvider>
  </StoreContext.Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
