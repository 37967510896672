import { makeAutoObservable, runInAction } from "mobx";
import { PublicClientApplication, AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import ApiAgent from "src/api/ApiAgent";
import { CreateAccountDto } from "src/models/CreateAccountDto";
import { loginRequest, silentRequest } from 'src/api/AuthConfig';
import { UserDetailDto } from "src/models/UserDetailDto";
import { FavoriteMenuDto } from  'src/models/FavoriteMenuDto'
import * as KeyNames from 'src/utils/KeyNames'

const LEVEL_FRANCHISEE_STAFF  = 2;
const LEVEL_FRANCHISEE_OWNER  = 9;
const LEVEL_JOB_COST          = 11;
const LEVEL_WEBUSER_ADMIN     = 13;
const LEVEL_FRANCHISEE_MASTER = 101;
const LEVEL_DESIGN_MASTER     = 102;
const LEVEL_PUBLIC_WEBSITE    = 103;
const LEVEL_HL_REF_INCLUSION  = 104;
const LEVEL_DISPLAY_HOME  = 105;

export default class UserStore {

  loginEmail:string = '';
  accessToken:string | null = null;
  
  msalApp: any = null;

  selectedAccount: any | null = null;

  sloBadgeTotal: number = 0;
  sloBadgeCurrent: number = 0;
  sloBadgeContract: number = 0;
  debt60Days: number = 0;
  belPendingCount: number = 0;
  webVersion: string = '';
  alertFeeForecast: boolean = false;

  favoriteMenus: FavoriteMenuDto[] = []; 

  selectedFranchisee: any = null;
  selectedState: any = null; 

  isVisibilitychange: boolean = false;

  proxyOriginEmail: string = '';
  isProxyMode: boolean = false; 

  constructor() {

    makeAutoObservable(this);
    console.log("isTesting: " + process.env.REACT_APP_IS_TESTING + ", Login Redirect: " + process.env.REACT_APP_LOGIN_REDIRECT + 
            ", API URL: " + process.env.REACT_APP_H360_API_URL);
  }

  initAuth() {

    console.log("UsrStore.initAuth() called...")
    
    this.msalApp = new PublicClientApplication({
        auth: {
            clientId: "49dadd04-c55a-449e-873f-d347255026e2",
            authority: "https://login.microsoftonline.com/hotondocomau.onmicrosoft.com",
            redirectUri: process.env.REACT_APP_LOGIN_REDIRECT,
            
        },
        cache: {
            cacheLocation: "localStorage", 
            storeAuthStateInCookie: false 
        },
    });

    const currentAccounts = this.msalApp.getAllAccounts();
    if (currentAccounts === null || currentAccounts.length === 0) {
      console.log("No stored accounts detected!");
    } 
    else if (currentAccounts.length >= 1) {

      this.loginEmail = currentAccounts[0].username;
    }
  } 

  async login() {

    console.log("UsrStore.login() called...")

    try {
      const response = await this.msalApp.loginPopup({scope: ["user.read"], prompt: "select_account"});
        runInAction(() => {

          if (response && response.account && response.account.username) {

            this.loginEmail = response.account.username;
                
            // clear all session storage caches....
            window.sessionStorage.clear();
          }
          else {
            console.error("msalApp.loginPopup response NULL...");
            this.logout();
          }
        })
    } catch(error) {
      this.msalApp["browserStorage"].clear();
      console.error("login fail: " + error);
    }
  }

  logout = async () => {

    const logoutRequest = {
      account: this.msalApp.getAccountByUsername(this.loginEmail),
      mainWindowRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT,
       postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT
    };

    try {
      const response = await this.msalApp.logoutPopup(logoutRequest);
      runInAction(() => {
        console.log("logout successful, ", response);
      })
    }
    catch(error) {
      console.error("login fail: " + error);
    }
    finally {

      this.loginEmail = '';
      this.selectedAccount = null;

      // clear session storage caches....
      window.sessionStorage.clear();

      // -------------------------------------------------------------------------
      // Remove Microsoft login local storage...
      // -------------------------------------------------------------------------
      let authKeys = [];
      for (let each in localStorage) {

        const keyName = each.toString();
        if( keyName.indexOf("login.windows.net") > 0 ) {
          // console.log("keyName: " + keyName);
          authKeys.push( keyName );
        }
      }

      // console.log("authKeys: "  + JSON.stringify(authKeys));
      authKeys.forEach(e => {
        window.localStorage.removeItem( e );
      });

      if (this.msalApp) {
        this.msalApp["browserStorage"].clear();
      }
    }
  }

  clearAndGotoHome = () => {

    this.loginEmail = '';
    this.selectedAccount = null;

    // clear session storage caches....
    window.sessionStorage.clear();

    // -------------------------------------------------------------------------
    // Remove Microsoft login local storage...
    // -------------------------------------------------------------------------
    let authKeys = [];
    for (let each in localStorage) {

      const keyName = each.toString();
      if( keyName.indexOf("login.windows.net") > 0 ) {
        // console.log("keyName: " + keyName);
        authKeys.push( keyName );
      }
    }

    // console.log("authKeys: "  + JSON.stringify(authKeys));
    authKeys.forEach(e => {
      window.localStorage.removeItem( e );
    });

    if (this.msalApp) {
      this.msalApp["browserStorage"].clear();
    }

    // Redirect To Home
    // window.location.replace(process.env.REACT_APP_LOGIN_REDIRECT);
  }
  
  acquireAccessToken = async () => {

    const redirectResponse = await this.msalApp.handleRedirectPromise();
    if (redirectResponse && redirectResponse.accessToken) {

      console.log("handleRedirectPromise, redirectResponse has token...");
      this.accessToken = redirectResponse.accessToken;
      return;
    }

    const myAccount = this.msalApp.getAccountByUsername(this.isProxyMode && this.proxyOriginEmail ? this.proxyOriginEmail : this.loginEmail);
    if(myAccount) {

      silentRequest.account = myAccount;
    }
    else {

      console.log("acquireTokenSilent getAccountByUsername return NULL... ");
      await this.logout();
      return;
    }


    try {
      const response = await this.msalApp.acquireTokenSilent(silentRequest);
      runInAction(() => {

        if (response && response.accessToken) {
          this.accessToken = response.accessToken;
        }
        else {
          console.log("acquireTokenSilent response NULL... ");
          this.logout();
        }
        
      })
    } 
    catch(error) {

      //Acquire token silent failure, and send an interactive request
      console.log("catch(error), " + error);

      if (error instanceof InteractionRequiredAuthError) {

        try {
          const response = await this.msalApp.acquireTokenRedirect(silentRequest);
          runInAction(() => {

            if (response && response.accessToken) {
              this.accessToken = response.accessToken;
            }
            else {
              console.log("accessToken is assigned by 'acquireTokenRedirect'");
              this.logout();
            }
          })
        }
        catch(err) {

          console.log("acquireTokenRedirect, ", err);
          await this.logout();
        }
      }
      else {

        console.log(error);
        await this.logout();
        // this.clearAndGotoHome();
      }
    } 
  }

  createApiAccount = async (value: CreateAccountDto) => {

    const response = await ApiAgent.WebUser.createAccount(value);
    // console.log("WebUser.createAccount(), response: " + JSON.stringify(response));
    return response;
  }

  requestAuthUserProxy = async (value: any) => {

    if (!this.accessToken) {
      await this.acquireAccessToken();
    }  

    const response = await ApiAgent.WebUser.authUserProxy(value);
    return response;
  }

  getApiAllAccounts = async() => {

    // console.log("UserStore.getApiAllAccounts() called.." + this.loginEmail);

    try {

      // 'getAllAccounts' dose not need authentication token....
      // if (!this.accessToken) {
      //   await this.acquireAccessToken();
      // }  

      if (this.loginEmail) {

        const response = await ApiAgent.WebUser.getAllAccounts(this.loginEmail);
        runInAction(() => {
          if (response) {

            // set sequenice id 
            let seqId = 1;
            response.forEach(function(item, index: any){ 
              item.id = seqId++; 
              item.selected = false;
            });

            // console.log("UserStore.getApiAllAccounts(), response: " + JSON.stringify(response));
            window.sessionStorage.setItem( KeyNames.UserAllAccounts, JSON.stringify(response));
          }
        })
      }
    }
    catch(error) {
      console.log(error);
    }
  }

  setSelectedAccount = async(value: UserDetailDto, byForce: boolean = false)  => {

    console.log("setSelectedAccount called, selectedAccount: " + (this.selectedAccount ? "already set " : "null " + ", byForce: ") + byForce);

    if (this.selectedAccount === null || byForce == true) {
      
      this.selectedAccount = value;
      window.sessionStorage.setItem( KeyNames.SelectedAccount, JSON.stringify(value) );

      // set user favorite menus..
      const menus = window.localStorage.getItem(KeyNames.FavoriteMenus);
      if (menus) {
        this.setFavoriteMenus(JSON.parse(menus));
      }

      // call user initial data load
      try {

        await this.acquireAccessToken();

        const response = await ApiAgent.WebUser.reqestUserInit(value);
        runInAction(() => {
          
          // console.log("WebUser.reqestUserInit(), response: " + JSON.stringify(response));

          this.setSloBadgeTotal(response.sloBadge.totalCount);
          this.setSloBadgeCurrent(response.sloBadge.currentCount);
          this.setSloBadgeContract(response.sloBadge.contractCount);
          this.setDebt60Days(response.debt60Days);
          this.setBelPendingCount( response.belPendingCount );
          
          // We do NOT need it but just keep it for future use...
          // this.setAlertFeeForecast( response.alertFeeForecast );
          this.setWebVersion( response.webVersion );
        })
      }
      catch(error) {
        console.log(error);
      }
    }
  }

  setSelectedAccountStorage = async(value: UserDetailDto)  => {

    // console.log("setSelectedAccountStorage called...", this.selectedAccount)

    // this.selectedAccount = value;
    window.sessionStorage.setItem( KeyNames.SelectedAccount, JSON.stringify(value) );
  }

  get isValidCurrentAccount() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }
    return true;
  }

  get isAuthenticated() {
 
    if (this.loginEmail === '' || this.loginEmail == null || this.loginEmail == undefined) {
      return false;
    }
    return true;
  }

  get isWebUserAdmin() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_WEBUSER_ADMIN);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isSalesToolAvailable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    // 10	==>	Sales + Leads
    if (this.selectedAccount.accessLevel == 10) {

      if (this.selectedAccount.salesToolAccess) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }

  get isFranchiseeOwner() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_FRANCHISEE_OWNER);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isFranchiseeStaff() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_FRANCHISEE_STAFF);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isLevelJobCost() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_JOB_COST);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isMarketingDashboardAccess() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    // console.log("this.selectedAccount.marketingDashboardAccess:", this.selectedAccount.marketingDashboardAccess);

    if (this.selectedAccount.marketingDashboardAccess) {
      return true;
    }
    else {
      return false;
    }
  }

  get isSetupChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    // console.log("this.selectedAccount.salesToolSetupChange:", this.selectedAccount.salesToolSetupChange);
    if (this.selectedAccount.salesToolSetupChange) {
      return true;
    }
    else {
      return false;
    }
  }

  get isStaffDetailsChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    // console.log("this.selectedAccount.myProfileStaffList:", this.selectedAccount.myProfileStaffList);
    if (this.selectedAccount.myProfileStaffList) {
      return true;
    }
    else {
      return false;
    }
  }

  get isFranchiseeMasterChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_FRANCHISEE_MASTER);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isDesignMasterChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_DESIGN_MASTER);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isPublicWebsiteChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_PUBLIC_WEBSITE);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isRefInclusionChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_HL_REF_INCLUSION);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isDisplayHomeChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    if (this.selectedAccount.allLevels) {
      const found = this.selectedAccount.allLevels.find(e => e == LEVEL_DISPLAY_HOME);
      if (found) {
        return true;
      }
    }
  
    return false;
  }

  get isFeeForecastChangeable() {

    if (this.selectedAccount == null || this.selectedAccount === undefined) {
      return false;
    }

    // console.log("this.selectedAccount:", JSON.stringify(this.selectedAccount));
    if (this.selectedAccount.feeForecastChange) {
      return true;
    }
    else {
      return false;
    }
  }

  setSloBadgeTotal(value: number) {
    this.sloBadgeTotal = value;
  }

  setSloBadgeCurrent(value: number) {
    this.sloBadgeCurrent = value;
  }

  setSloBadgeContract(value: number) {
    this.sloBadgeContract = value;
  }

  setDebt60Days(value: number) {
    this.debt60Days = value;
  }

  setBelPendingCount(value: number) {
    this.belPendingCount = value;
  }

  setWebVersion(value: string) {
    this.webVersion = value;
  }
  
  setAlertFeeForecast(value: boolean) { 
    this.alertFeeForecast = value;
  }

  setAccessToken(value: string | null) {
    this.accessToken = value;
  }

  setLoginEmail(value: string) {
    this.loginEmail = value;
  }

  setClearSelectedAccount(value: null) {
    this.selectedAccount = value;
  }

  setFavoriteMenus(value: FavoriteMenuDto[]) {

    if (value) {
      this.favoriteMenus = value;
    }
  }

  addFavoriteMenu(value: FavoriteMenuDto) {

    const index = this.favoriteMenus.findIndex( e => {
      return e.path == value.path;
    });
    // const found = this.favoriteMenus.find(e => e.path == value.path);
    if (index < 0) {
      this.favoriteMenus.push(value);

      // save 'Favorite Menu' value from local storage...
      window.localStorage.setItem(KeyNames.FavoriteMenus, JSON.stringify(this.favoriteMenus));
    }
  }

  deleteFavoriteMenu(value: string) {

    const index = this.favoriteMenus.findIndex( e => {
      return e.path == value;
    });

    if (index > -1) {
      this.favoriteMenus.splice(index, 1);

      // save 'Favorite Menu' value from local storage...
      window.localStorage.setItem(KeyNames.FavoriteMenus, JSON.stringify(this.favoriteMenus));
    }
  }

  renameFavoriteMenu(path: string, newName: string) {

    const index = this.favoriteMenus.findIndex( e => {
      return e.path == path;
    });

    if (index > -1) {
      this.favoriteMenus[ index ].name = newName;

      // save 'Favorite Menu' value from local storage...
      window.localStorage.setItem(KeyNames.FavoriteMenus, JSON.stringify(this.favoriteMenus));
    }
  }

  setSelectedFranchisee(value: any) {
    this.selectedFranchisee = value;
  }

  setSelectedState(value: any) {
    this.selectedState = value;
  }

  setVisibilitychange(value: boolean) {
    this.isVisibilitychange = value;
  }

  setProxyOriginEmail(value: string) {
    this.proxyOriginEmail = value;
  }

  setProxyMode(value: boolean) {
    this.isProxyMode = value;
  }

}