import React, { useState, useEffect } from 'react'
import { Button, Header, Modal, Icon, Tab, Checkbox } from 'semantic-ui-react'
import { useStore } from 'src/stores/store'
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';
import { runInAction } from 'mobx';
import * as KeyNames from 'src/utils/KeyNames'

const AlertDialog = () => {

  const {userStore, commonStore} = useStore();

  const onHandleCloseButton = () => {
    commonStore.setAlertMsgOpen(false);
  };

  const onHandleReloadClick = () => {

    // Save current web version to local storage...
    window.localStorage.setItem(KeyNames.ApplicationVersion, userStore.webVersion);

    // Delete caches...
    caches.keys().then((names) => {
        names.forEach(async (name) => {
            await caches.delete(name)
        })
    });

    window.location.reload();
  }

  const isOpenDialog = commonStore.alertMsg.isOpen;
  const isSuccess = commonStore.alertMsg.isSuccess;
  const isReload = commonStore.alertMsg.isReload;
  
  return (
    <>
      <Modal
        closeIcon={{ style: { top: '1rem', right: '1rem' }, color: 'black', name: 'close' }}
        open={isOpenDialog}
        onClose={onHandleCloseButton}
        size={"small"}
        style={{zIndex: "1500", margin: "auto"}}
      >
        <Header>
          <Icon name={isSuccess ? 'check' : 'exclamation circle'} color={isSuccess ? 'green' : 'red'} />
          <Header.Content>{commonStore.alertMsg.title}</Header.Content>
        </Header>
        <Modal.Content>
          <Modal.Description>
            {commonStore.alertMsg.message ? parse(commonStore.alertMsg.message) : ""}
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          {isReload && 
          <Button color='teal' onClick={onHandleReloadClick}>
            <Icon name='refresh' />RELOAD
          </Button>
          }
          <Button color='yellow' onClick={onHandleCloseButton}>
            <Icon name='close' />CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  ) 
}

export default observer(AlertDialog)


