// For a full list of MSAL.js configuration parameters, 
// Coordinates and required scopes for your web API
export const apiConfig = {
    resourceUri: "http://localhost:44351/api/profile",
    resourceScopes: ["api://49dadd04-c55a-449e-873f-d347255026e2/AdminAccess"]
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", ...apiConfig.resourceScopes]
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile",  ...apiConfig.resourceScopes],
    account: null
}
