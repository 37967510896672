import { makeAutoObservable, runInAction } from "mobx";

export default class AdminStore {

  // Design Master Part...
  designDetails: null | any = null;
  memberWebsite: null | any = null;
  publicWebsite: null | any = null;
  publicImages: null | any = null;
  designFacade: null | any = null;
  designRoom: null | any = null;

  detailsValueChanged: boolean = false;
  memberWebValueChanged: boolean = false;
  publicWebValueChanged: boolean = false;
  imagesValueChanged: boolean = false;

  needDesignSeriesWarning: boolean = false;

  // Franchisee Master Part...
  franchiseeGeneral: null | any = null;
  keyDates: null | any = null;
  suspend: null | any = null;
  territories : null | any = null;
  buildPostcodes: null | any = null;
  social: null | any = null;

  generalValueChanged: boolean = false;
  keyDatesChanged: boolean = false;
  suspendChanged: boolean = false;
  // territoriesChanged: boolean = false;
  socialChanged: boolean = false;

  // All Franchisee Reports Part...
  reportFranchiseeList: null | any[] = null;
  franchiseeHistory: null | any = null;
  otherFranchiseeHistory: null | any = null;
  customDateFilter: null | any = null;
  customDateData: null | any = null;

  // Display Home Part...
  builderDetails: null | any = null;
  openTimeList: null | any[] = null;
  approvalProcess: null | any = null;

  builderValueChanged: boolean = false;
  openTimeListChanged: boolean = false;
  processValueChanged: boolean = false;

  // Territory Definition Part..
  territoryDetails: null | any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDesignDetails(value: any) {
    this.designDetails = value;
    this.detailsValueChanged = true;
  }

  setMemberWebsite(value: any) {
    this.memberWebsite = value;
    this.memberWebValueChanged = true;
  }

  setPublicWebsite(value: any) {
    this.publicWebsite = value;
    this.publicWebValueChanged = true;
  }

  setPublicImages(value: any) {
    this.publicImages = value;
    this.imagesValueChanged = true;
  }

  setDesignFacade(value: any) {
    this.designFacade = value;
  }

  setDesignRoom(value: any) {
    this.designRoom = value;
  }

  setDetailsValueChanged(value: boolean) {
    this.detailsValueChanged = value;
  }

  setMemberWebValueChanged(value: boolean) {
    this.memberWebValueChanged = value;
  }

  setPublicWebValueChanged(value: boolean) {
    this.publicWebValueChanged = value;
  }

  setImagesValueChanged(value: boolean) {
    this.imagesValueChanged = value;
  }

  setNeedDesignSeriesWarning(value: boolean) {
    this.needDesignSeriesWarning = value;
  }

  // ----------------------------------------------------------------------------
  // Franchisee Master Part...
  // ----------------------------------------------------------------------------
  setFranchiseeGeneral(value: any) {
    this.franchiseeGeneral = value;
    this.generalValueChanged = true;
  }

  setGeneralValueChanged(value: boolean) {
    this.generalValueChanged = value;
  }

  setKeyDates(value: any) {
    this.keyDates = value;
    this.keyDatesChanged = true;
  }

  setKeyDatesChanged(value: boolean) {
    this.keyDatesChanged = value;
  }

  setSuspend(value: any) {
    this.suspend = value;
    this.suspendChanged = true;
  }

  setSuspendChanged(value: boolean) {
    this.suspendChanged = value;
  }

  setTerritories(value: any) {
    this.territories = value;
    // this.territoriesChanged = true;
  }

  // setTerritoriesChanged(value: boolean) {
  //   this.territoriesChanged = value;
  // }

  setBuildPostcodes(value: any) {
    this.buildPostcodes = value;
  }

  setSocial(value: any) {
    this.social = value;
    this.socialChanged = true;
  }

  setSocialChanged(value: boolean) {
    this.socialChanged = value;
  }

  // ----------------------------------------------------------------------------
  //  All Franchisee Reports Part...
  // ----------------------------------------------------------------------------
  setReportFranchiseeList(value: any) {
    this.reportFranchiseeList = value;
  }

  setFranchiseeHistory(value: any) {
    this.franchiseeHistory = value;
  }

  setOtherFranchiseeHistory(value: any) {
    this.otherFranchiseeHistory = value;
  }

  setCustomDateFilter(value: any) {
    this.customDateFilter = value;
  }

  setCustomDateData(value: any) {
    this.customDateData = value;
  }

  // ----------------------------------------------------------------------------
  // Display Home Part...
  // ----------------------------------------------------------------------------
  setBuilderDetails(value: any) {
    this.builderDetails = value;
    this.builderValueChanged = true;
  }

  setOpenTimeList(value: any) {
    this.openTimeList = value;
    this.openTimeListChanged = true;

    if (this.builderDetails) {
      this.builderDetails.isOpenTimeChanged = true;
    }
  }

  setApprovalProcess(value: any) {
    this.approvalProcess = value;
    this.processValueChanged = true;
  }

  setBuilderValueChanged(value: boolean) {
    this.builderValueChanged = value;
  }

  setOpenTimeListChanged(value: boolean) {
    this.openTimeListChanged = value;
  }
  
  setProcessValueChanged(value: boolean) {
    this.processValueChanged = value;
  }

  setTerritoryDetails(value: any) {
    this.territoryDetails = value;
  }
}
