import React, { useEffect, Component, useCallback } from 'react'
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.min.css';
import '@progress/kendo-theme-default/dist/all.css';
import './styles.css'
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import { useStore } from './stores/store'
import ModalContainer from './components/modal/ModalContainer';
import AlertDialog from 'src/dialogs/AlertDialog'
import * as KeyNames from 'src/utils/KeyNames'
import { runInAction } from 'mobx';
import ApiAgent from "src/api/ApiAgent";
import moment from 'moment';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/Login'))
// const Register = React.lazy(() => import('./pages/Register'))
const Page404 = React.lazy(() => import('./pages/Page404'))
const Page500 = React.lazy(() => import('./pages/Page500'))
const SignUpPage = React.lazy(() => import('./pages/SignUpPage'))

const getUserConfirmation = (dialogKey, callback) => {
  // use "message" as Symbol-based key
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }

  // Fallback to allowing navigation
  callback(true);
};

const App = () => {

  const {userStore, commonStore} = useStore();

  useEffect(() => {

    userStore.initAuth();
    if (userStore.isAuthenticated) {

      const accountRaw = window.sessionStorage.getItem(KeyNames.SelectedAccount);
      if (accountRaw) {

        const selectedAccont = JSON.parse(accountRaw);
        userStore.setSelectedAccount( selectedAccont );
      } 
    }

    window.addEventListener('focus', onHandleFocusChange);
    // document.addEventListener('visibilitychange', onHandleVisibilityChange);
    return () => {
      window.removeEventListener('focus', onHandleFocusChange);
      // document.removeEventListener('visibilitychange', onHandleVisibilityChange )
    }
  }, []);

  const onHandleFocusChange = useCallback(() => {

    if (userStore.isValidCurrentAccount) {

      requestApplicationVersionAsync();
    }
  }, []);

  const onHandleVisibilityChange = useCallback(() => {
    
    if (document.visibilityState === 'visible' && userStore.isValidCurrentAccount) {

      requestApplicationVersionAsync();
    }
  }, []);

  async function requestApplicationVersionAsync() {

    const lastReqStr = window.sessionStorage.getItem(KeyNames.LastVersionTime);
    if (lastReqStr) {
      
      const lastReqTime = moment(lastReqStr, "DD/MM/YYYY HH:mm:ss");
      const compareTime = moment().add(-7, 'minutes');

      if (lastReqTime > compareTime) {
        // console.log("request within 7 minutes....");
        return;
      }
    }

    // set version request time to session cache...
    window.sessionStorage.setItem(KeyNames.LastVersionTime, moment().format('DD/MM/YYYY HH:mm:ss'));

    // Home request does NOT need access token...
    // await userStore.acquireAccessToken();

    try {

      userStore.setVisibilitychange( true );
  
      const response = await ApiAgent.Home.getHomeVersion();
      runInAction(() => {

        if (response) {

          const tempVersion = response.trim();
          const thisVersion = window.localStorage.getItem(KeyNames.ApplicationVersion);

          if (thisVersion) {

            if (thisVersion != tempVersion) {
              console.log("App, New Version:", tempVersion + ", This Version: " + thisVersion);

              // Save this version to User Store. After clicking reload button, this version will be saved to Local Store...
              userStore.setWebVersion( tempVersion );

              commonStore.setAlertMsg({isOpen: true, isSuccess: false, isReload: true, 
                title: "New Version Released", 
                message: "<span style='font-size: 16px'>To refresh application, please click the below 'RELOAD' button</span><br><br><span style='font-size: 13px'>If you still have this issue, please logout and login again</span>"});
              }
          }
          else {
            console.log("App, Version:", tempVersion);
            window.localStorage.setItem(KeyNames.ApplicationVersion, tempVersion);
          }
        }
      })
    }
    catch(error) {
      console.log(error);
    }
    finally{
      userStore.setVisibilitychange( false );
    }
  }

  return (
    <>
      <ToastContainer position='top-center' hideProgressBar={false} closeOnClick pauseOnHover style={{width: "fit-content"}} autoClose={4000} />
      <ModalContainer />
      <AlertDialog/>     

      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            {/* <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} /> */}
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/signup" name="Sign Up" render={(props) => <SignUpPage {...props} />} />

            {/* <Route exact path="/marketing/edm" render={() => (<Redirect to="/marketing/edm2" />)} />   
            <Route exact path="/sales/salesEstimatingTool/setup" render={() => (<Redirect to="/sales/salesEstimatingTool/setup2" />)} />    */}

            <Route path="/" name="Home"> {userStore.isAuthenticated ? <DefaultLayout /> : <Login />}</Route>
          </Switch>
        </React.Suspense>
      </BrowserRouter>


    </>
  )
}

export default observer(App);
