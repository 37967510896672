import { createContext, useContext } from "react";
import UserStore from "./UserStore";
import CommonStore from "./CommonStore";
import ModalStore from "./ModalStore";
import SalesStore from "./SalesStore";
import RecruitmentStore from './RecruitmentStore';
import FinanceStore from "./FinanceStore";
import HomeStore from "./HomeStore"
import MyBusinessStore from "./MyBusinessStore";
import MarketingStore from "./MarketingStore"
import SupportStore from "./SupportStore"
import AdminStore from "./AdminStore"

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    salesStore: SalesStore;
    recruitmentStore:RecruitmentStore;
    financeStore: FinanceStore;
    homeStore: HomeStore;
    myBusinessStore: MyBusinessStore;
    marketingStore: MarketingStore;
    supportStore: SupportStore;
    adminStore: AdminStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    salesStore: new SalesStore(),
    recruitmentStore: new RecruitmentStore(),
    financeStore: new FinanceStore(),
    homeStore: new HomeStore(),
    myBusinessStore: new MyBusinessStore(),
    marketingStore: new MarketingStore(),
    supportStore: new SupportStore(),
    adminStore: new AdminStore(),
}

export const StoreContext = createContext(store);

export function useStore() {

    return useContext(StoreContext);
}